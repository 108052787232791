import React from 'react';
import '../../styles/Ads.css';

function Ads() {

  return (
    <>
       {/* Latest Removed */}
        {/* <div class="modal fade" id="onload" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button className='close' data-bs-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                       
                       

                        <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">d
                            
                            <div class="carousel-inner">
                                 <div class="carousel-item active">
                                    <img src="/vscimages/ads/mid-year-ad.png" class="d-block w-100" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img src="/vscimages/ads/vsclub-5.5.jpg" class="d-block w-100" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img src="/vscimages/ads/discount-ad.jpg" class="d-block w-100" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img src="/vscimages/ads/Coach ROY2 -ig.jpg" class="d-block w-100" alt="" />
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  */}
        
        {/* AFTER OF MODAL-BODY POSITION BEFORE */}
        {/* <img src="/vscimages/ads/VS Erick Butler-ig.jpg" alt="" /> */}
    </>
  )
}

export default Ads